.LoadingPage {
    width: 100%;
}

.LoadingPage img {
    width: 300px;
    animation: rotation 3.14s infinite cubic-bezier(0.46, 0.03, 0.52, 0.97);
}

.LoadingPage .Text {
    position: absolute;
    top: calc(var(--header-height) + (1 * var(--banner-height)))
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359.99deg);
    }
}