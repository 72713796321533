.About {
    margin-bottom: 1em;
}

.About h2 {
    margin: 0;
    padding: 1em;
}

.About .bookLengthy,
.About .AboutMe {
    display: grid;
}

.About .bookLengthy .content {
    background-color: #ec526c;
    border-radius: 0 20px 20px 0;
}

.About .AboutMe .content {
    background-color: #b791f5;
    border-radius: 20px 0 0 20px;
}

.About .bookLengthy .content,
.About .AboutMe .content {
    display: flex;
    padding-left: 1ch;
}

@media screen and (min-width:990px) {
    .About {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }
}

@media screen and (min-width:641px) {
    .About .bookLengthy {
        grid-template-columns: 1fr 4fr;
    }

    .About .AboutMe {
        grid-template-columns: 4fr 1fr;
    }

    .About .AboutMe .content {
        justify-content: space-between;
        border-radius: 20px 0 0 20px;
        padding-right: 2ch;
    }

    .About p {
        font-size: large;
        max-width: 80%;
    }

    .About img {
        height: 10em;
        margin: 0.5ch;
    }
}

@media screen and (max-width:640px) {

    .About .bookLengthy {
        display: grid;
        grid-template-columns: 1fr 7fr;
    }

    .About .AboutMe {
        display: grid;
        grid-template-columns: 7fr 1fr;
    }

    .About .bookLengthy .content,
    .About .AboutMe .content {
        flex-direction: column;
    }

    .About .bookLengthy .content img {
        transform: rotate(90deg);
        scale: 1.5;
    }

    .About .AboutMe .content img {
        transform: rotate(-90deg);
        scale: 1.5;
    }

    .About p {
        font-size: large;
        padding-right: 1.5ch;
    }

    .About img {
        height: 4em;
        margin: 0.5ch;
    }
}