.ConditionList,
.ConditionList table {
    width: 100%;
}

.ConditionList table {
    text-align: center;
}

.ConditionList th {
    background-color: gray;
}

.ConditionList tbody tr:last-child>* {
    border-bottom: 0px solid !important;
    padding: 0 2ch !important;
}