.Layout {
    height: 100%;
    --header-height: 2rem;
    --footer-height: 4.5rem;
    --banner-height: 1.75rem;
}

.Layout.dark {
    background-color: #0C0C0C;
}

.Layout .stick {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
}