.StoreButton {
    display: flex;
    justify-content: center;
}

.StoreButton button {
    background-color: #684788;
    padding: 1ch 3ch;
    margin: 1em 0;
    border-radius: 15px 0;
    border: 1px solid black;
    box-shadow: 10px 5px 10px gray;
    font-size: xx-large;
    font-family: "patiach";
    color: black;
}