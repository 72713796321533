.ContactMe {
    min-height: calc(100vh - var(--header-height) - var(--banner-height) - var(--footer-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ContactMe .container {
    display: flex;
    justify-content: center;
}

.ContactMe .container img {
    margin: 1ch;
    width: 40px;
    height: 40px;
}

.ContactMe form {
    margin: 1em;
}

.ContactMe abbr {
    color: red;
    text-decoration: none;
}

/* .ContactMe textarea {
    overflow-y: auto;
} */

.ContactMe .form-container {
    display: flex;
    flex-direction: column;
    max-width: 450px;
}

.ContactMe .form-container>* {
    margin: 0.5em 2ch;
}

.ContactMe form button {
    width: 100%;
    max-width: 450px;
    background-image: linear-gradient(105deg, #ba7f2b, #392200);
    border-top: 2px solid #d9932b;
    border-right: 2px solid #5c4016;
    border-bottom: 2px solid #5c4016;
    border-left: 2px solid #d9932b;
    border-radius: 2px;
    font-family: "FrankRuehl";
    font-size: x-large;
    line-height: 1.4;
}