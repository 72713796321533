.Store>div {
    box-sizing: border-box;
    padding-top: 2em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.Store .price {
    margin-top: 1em;
    text-align: center;
}

.Store>div>div {
    min-width: 275px;
    position: relative;
    padding-bottom: 3.5em;
}

.Store .selection {
    text-align: center;
    font-size: xx-large;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 0.75ch;
}

.Store .selection>a {
    color: #789469;
    text-decoration: none;
    font-family: 'Gutman Vilna';
    font-weight: bold;
}