@import url('https://fonts.googleapis.com/css2?family=Foldit:wght@900&display=swap');

.FirstChapter button {
    margin-top: 1em;
    width: 100%;
    background-image: linear-gradient(15deg, #ba7f2b, #392200);
    border-top: 2px solid #d9932b;
    border-right: 2px solid #5c4016;
    border-bottom: 2px solid #5c4016;
    border-left: 2px solid #d9932b;
    border-radius: 2px;
    font-family: "FrankRuehl";
    font-size: larger;
    line-height: 1.4;
    color: antiquewhite;
}

.FirstChapter .Back,
.FirstChapter .Forward {
    font-size: 3em;
    z-index: 2000;
    color: gold;
    position: absolute;
    top: 110%;
    font-family: 'Foldit', sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.FirstChapter .Forward {
    left: 0.5ch;
}

.FirstChapter .Back {
    right: 0.5ch;
}

.FirstChapter>.modal {
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000000aa;
}

.FirstChapter .modal .container {
    display: flex;
    justify-content: center;
    height: 85%;
    width: 85%;
}

@media screen and (max-width:599px) {
    .FirstChapter .modal .container>div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .FirstChapter .Back,
    .FirstChapter .Forward {
        top: 47%;
    }

    .FirstChapter .Forward {
        left: 0.1ch;
    }

    .FirstChapter .Back {
        right: 0.1ch;
    }
}

.FirstChapter .modal img {
    width: 100%;
    max-width: 85vw;
}

.FirstChapter .modal .container {
    overflow-y: auto;
}

.FirstChapter .modal .container>div {
    position: relative;
    width: 100%;
    height: 100%;
}