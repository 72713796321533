.dark .Admin button,
.dark .Admin input,
.dark .Admin select,
.dark .Admin option,
.dark .Admin textarea {
    color: black;
}

.dark .Admin input[type=file] {
    color: whitesmoke;
}

.Admin {
    margin: 0 1ch;
}