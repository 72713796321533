.Footer {
    text-align: center;
    width: 100%;
    height: var(--footer-height);
    background-color: black;
    color: white;
}

.Footer .container {
    display: flex;
    justify-content: center;
}

.Footer .copyright {
    margin: 0 1ch;
}