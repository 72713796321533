.PermaBanner>div {
    width: 100%;
    font-size: larger;
    height: var(--banner-height);
    background-color: #ba7f2b;
    color: white;
    text-align: center;
}

.PermaBanner {
    text-decoration: none;
}