.PageNotFound .content-container {
    padding-right: 1ch;
}

.PageNotFound p {
    padding-right: 1ch;
}

.PageNotFound a {
    font-size: xx-large;
    text-decoration: none;
}

.PageNotFound .gif-container {
    display: flex;
    justify-content: center;
}

.PageNotFound img {
    width: 98vw;
    max-width: 650px;
    margin-bottom: 2ch;
}