.CouponList th {
    border-bottom: 3px solid;
    margin: 0;
    padding: 0;
}

.CouponList tbody tr>* {
    border-bottom: 1px solid;
    padding: 0 2ch;
}

.CouponList tbody tr:last-child>* {
    border-bottom: 0px solid;
}