.Book {
    margin: 0.5em 2em 2em 0;
}

@media screen and (min-width:320px)  {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .Book {
        margin-left: 1ch;
    }
    .Book .Summary{
        margin-top: 1em;
    }
}
@media screen and (min-width:600px)  {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .Book {
        display: flex;
    }
}

.Book > .Summary {
    font-size: x-large;
    margin-right: 5%;
    margin-left: 2%;
}

.Book > .Summary > .vertical-center {
    height: min-content;
    max-width: 95%;
}

.Book > .container {
    display: grid;
    grid-template-rows: repeat(1fr, 2);
}