:root {
  direction: rtl;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: whitesmoke;
  position: relative;
}

.margin-top-2 {
  margin-top: 2em;
}

.vertical-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: "patiach";
  src: url("Assets/Fonts/caligraphpatiach.woff") format("woff2")
}

.dark *{
  color: whitesmoke;
}

.dark .MuiFormControl-root *{
  color: whitesmoke;
  -webkit-text-fill-color: whitesmoke;
}

.dark .MuiOutlinedInput-notchedOutline {
  border-color: burlywood !important;
}

.dark .Mui-disabled>fieldset {
  border-color: beige !important;
}

.dark .MuiAutocomplete-popper * , .dark .MuiPaper-root *{
  background-color: #272727 !important;
}

.error-message {
  font-size: small;
  color: darkred;
}

.error-field {
  border-color: darkred;
}

.form-container div {
  position: relative;
}

.form-container .error-message {
  margin: 0.5ch 0.3ch;
  position: absolute;
  right: 0;
}

.form-container .MuiTextField-root+.error-message {
  top: 4em;
}

.form-container textarea {
  resize: vertical;
}

.MuiTextField-root,
.MuiInputBase-root {
  direction: ltr;
  width: 100%;
}