.ShortAbout {
    margin: 0 auto;
    background-color: rgba(128, 225, 255, 0.133);
    width: 80%;
    max-width: 1250px;
    padding: 0.5ch 2ch;
}

.ShortAbout .content img {
    width: 250px;
    margin: 0 auto 1ch auto;
}

.ShortAbout .content p {
    margin: 0 1ch;
    min-width: 30ch;
}

.ShortAbout .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    font-size: large;
}

.ShortAbout .content a {
    font-weight: bold;
}