.OrderList thead *{
    border-bottom: 3px solid;
    margin: 0;
    padding: 0;
}

.OrderList tbody tr>* {
    border-bottom: 1px solid;
    padding: 0 2ch;
}

.OrderList tbody tr:last-child>* {
    border-bottom: 0px solid;
    padding: 0 2ch;
}

.OrderList .details {
    margin-top: 1em;
}