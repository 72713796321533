.DeliveryInfo {
    margin-top: 1em;
}

.DeliveryInfo h2 {
    text-align: center;
}

.DeliveryInfo form .OrderButton {
    display: grid;
    margin: 0 auto 1em auto;
}

.DeliveryInfo form .OrderButton button {
    background-color: #684788;
    padding: 1ch 3ch;
    margin: 0.5em auto;
    border-radius: 15px 0;
    border: 1px solid black;
    box-shadow: 10px 5px 10px gray;
    font-size: xx-large;
    font-family: "patiach";
    width: max-content;
    color: black;
}

.DeliveryInfo form .OrderButton.Invalid button {
    background-color: #860e0a !important;
}

.DeliveryInfo form .OrderButton.Disabled button {
    background-color: #414042 !important;
}

.DeliveryInfo form .OrderButton button>img{
    mix-blend-mode: multiply;
    height: 1.25em;
    aspect-ratio: 113 / 25;
}

.DeliveryInfo form .OrderButton span {
    font-size: small;
    margin: 0 auto;
}

.DeliveryInfo .confirmation {
    width: 80%;
    background-color: #6cae45;
    border-radius: 15px;
    margin: 0 auto 1em auto;
    padding: 0.5ch 1ch;
}

.DeliveryInfo .confirmation .content {
    padding-right: 2ch;
}

.DeliveryInfo .confirmation .content span {
    color: darkred;
}

.DeliveryInfo .form-container {
    display: grid;
    margin: 0 auto;
    max-width: 64em;
}

.DeliveryInfo .form-container>* {
    margin: 1.5ch 2ch;
}

.DeliveryInfo .show {
    animation: pop-in 0.5s;
}

.DeliveryInfo .hide {
    animation: pop-out 0.5s;
    opacity: 0;
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.1);
    }
}

.DeliveryInfo .first {
    grid-area: first;
}

.DeliveryInfo .last {
    grid-area: last;
}

.DeliveryInfo .email {
    grid-area: email;
}

.DeliveryInfo .phone {
    grid-area: phone;
}

.DeliveryInfo .city {
    grid-area: city;
}

.DeliveryInfo .street {
    grid-area: street;
}

.DeliveryInfo .house {
    grid-area: house;
}

.DeliveryInfo .zip {
    grid-area: zip;
}

.DeliveryInfo .dedicate {
    grid-area: dedicate;
}

.DeliveryInfo .dedication_name {
    grid-area: dedication_name;
}

.DeliveryInfo .comments {
    grid-area: comments;
}

.DeliveryInfo .amount {
    grid-area: amount;
}

.DeliveryInfo .cost {
    grid-area: cost;
}

.DeliveryInfo .total {
    grid-area: total;
    font-size: large;
}

.DeliveryInfo .coupon {
    grid-area: coupon;
    display: flex;
}

.DeliveryInfo .coupon button {
    color: #684788;
    background: none;
    border: none;
    font-size: large;
    margin-right: 1ch;
}

@media screen and (min-width:320px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .DeliveryInfo .form-container {
        grid-template-areas: "first           first"
                             "last            last"
                             "email           email"
                             "phone           phone"
                             "city            city"
                             "street          street"
                             "house           zip"
                             "coupon          coupon"
                             "comments        comments"
                             "amount          dedicate"
                             "dedication_name dedication_name"
                             "cost            cost"
                             "total           total";
    }
}

@media screen and (min-width:600px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .DeliveryInfo .form-container {
        grid-template-areas: "first           last"
                             "email           email"
                             "phone           city"
                             "street          house"
                             "zip             coupon"
                             "comments        comments"
                             "dedicate        dedication_name"
                             "amount          amount"
                             "cost            cost"
                             "total           total";
    }
}