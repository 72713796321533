.Banner>div {
    width: 100%;
    font-size: larger;
    height: var(--banner-height);
    background-color: #dc9e46;
    color: white;
    text-align: center;
}

.Banner {
    text-decoration: none;
}