.Cypher .ltr {
    direction: ltr !important;
}

.Cypher hr {
    margin: 0 1ch;
}

.Cypher {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0 auto 1em auto;
    max-width: 1250px;
    text-align: center;
    font-size: x-large;
    position: relative;
}

.Cypher>img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1;
    border-radius: 1em;
}

.Cypher h3 {
    margin: 0;
    padding: 1ch 0;
    display: flex;
    justify-content: space-evenly;
}

.Cypher h3>img {
    height: 1em;
    transform: translateY(0.25em);
}