.Header {
    width: 100%;
    background-color: black;
    height: var(--header-height);
    line-height: 100%;
}

.Header .container a.active {
    color: rgb(176, 176, 238);
}

.Header .container a {
    font-family: "FrankRuehl";
    font-size: x-large;
    text-decoration: none;
    color: whitesmoke
}

.Header > .container {
    display: flex;
    width: 85%;
    justify-content:space-evenly;
    margin: 0 auto;
}